import '../App.css';
import './Realisations.css'

import first from '../assets/realisations/IMG_1808.webp'
import second from '../assets/realisations/IMG_2284.webp'
import third from '../assets/realisations/IMG_0700.webp'
import fourth from '../assets/realisations/IMG_9771.webp'
import fifth from '../assets/realisations/IMG_0765.webp'

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { AiFillPlusCircle } from "react-icons/ai";

/*
const isVerticalImage = (image) => {
  return image.naturalWidth < image.naturalHeight;
};*/

const isMobile = window.matchMedia('(max-width: 768px)').matches;


function Realisations() {
  console.log(isMobile)
  return (
    <>
      <div className='realisations' id="realisations">

        <div className='realisations-title'>
          <h1>Nos réalisations</h1>
        </div>

        

      {isMobile ? (
        <>
          <div> MOBILE </div>
        </>
      ) : (
        <>
        <div className='realisations-body'>
          <div className='realisations-ctr'>
            <Image src={first} thumbnail className='realisations-img'/>
          </div>

          <div className='realisations-ctr'>
            <Image src={third} thumbnail className='realisations-img'/>
          </div>

          <div className='realisations-ctr'>
            <Image src={second} thumbnail className='realisations-img'/>
          </div>
        </div>

        <div className='realisations-body second-row'>
          <div className='realisations-ctr second-row-ctr'>
            <Image src={fourth} thumbnail className='realisations-img second-row-img'/>
          </div>

          <div className='realisations-ctr second-row-ctr'>
            <Image src={fifth} thumbnail className='realisations-img second-row-img'/>
          </div>
        </div>
        </>
      )}

    <div className='waves'></div>

    </div>
    </>
  );
}

export default Realisations;
